import css from 'styled-jsx/css';

export default css`.default {
  transform: translate(-33%, -33%); }
  .default.lottie-container {
    width: 50px;
    height: 50px;
    border-radius: 50%; }
  .default.popbox-container {
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: 0px;
    right: 16px;
    transform: translate3d(0, 0, 0); }
`