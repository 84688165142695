/**
 * Reference:
 *   https://schema.org/NewsArticle
 */

type ImageObject = {
  '@type': 'ImageObject'
  url: string
  thumbnailUrl?: string
  width: number
  height: number
}

type ImageObjectInput = Omit<ImageObject, '@type'>

type Person = {
  '@type': 'Person'
  name: string
}

type PersonInput = Omit<Person, '@type'>

type Organization = {
  '@type': 'Organization'
  name: string
  url: string
  logo: ImageObject
}

type OrganizationInput = { logo?: ImageObjectInput } & Omit<Organization, '@type' | 'logo'>

type NewsArticle = {
  '@context': 'https://schema.org'
  '@type': 'NewsArticle'
  headline: string
  description: string
  articleBody: string
  mainEntityOfPage: string
  datePublished: string
  dateModified: string
  keywords: string[]
  image: ImageObject
  publisher: Organization
  author: Person
  thumbnailUrl: string
}

type NewsArticleInput = {
  image: ImageObjectInput
  publisher: OrganizationInput
  author: PersonInput
} & Omit<NewsArticle, '@context' | '@type' | 'image' | 'publisher' | 'author'>

const getNewsArticle = (input: NewsArticleInput): NewsArticle => {
  const { image, publisher, author } = input
  const { url, thumbnailUrl, width, height } = image // 取出 google structured data 需要的部分

  const logo: ImageObject = publisher.logo
    ? { '@type': 'ImageObject', ...publisher.logo }
    : {
        '@type': 'ImageObject',
        url: 'https://static.popdaily.com.tw/statics/images/popdaily-logo.svg',
        width: 300,
        height: 130,
      }

  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    ...input,
    image: image && {
      url,
      thumbnailUrl,
      width,
      height,
      '@type': 'ImageObject',
    },
    author: { '@type': 'Person', ...author },
    publisher: {
      '@type': 'Organization',
      ...publisher,
      logo,
    },
  }
}

export default getNewsArticle
