import { gql } from '@apollo/client'

const GET_CATEGORIES = gql`
  query GetCategories($categoryType: CategoryType, $categoryEnableType: CategoryEnableType) {
    categories(categoryType: $categoryType, categoryEnableType: $categoryEnableType) {
      id
      name
      enabled
      type
      hashTags
      description
      endTime
      startTime
      editor
      creator
      interest
      postID
    }
  }
`

const DEFAULT_CATEGORIES = [
  { id: 'beauty', name: '美妝' },
  { id: 'life', name: '生活' },
  { id: 'food', name: '發胖' },
  { id: 'travel', name: '打卡' },
  { id: 'style', name: '穿搭' },
  { id: 'korea', name: '泡菜' },
  { id: 'japan', name: '日本' },
  { id: 'entertainment', name: '娛樂' },
  { id: 'constellation', name: '星座' },
  { id: 'love', name: '戀愛' },
  { id: 'pet', name: '寵物' },
  { id: 'mama', name: '媽咪兒' },
  { id: 'sport', name: '運動' },
  { id: 'diary', name: '日記' },
]

export const getCategories = async (client) => {
  try {
    const result = await client.query({ query: GET_CATEGORIES })
    return result.data.categories
  } catch (error) {
    return DEFAULT_CATEGORIES
  }
}

export const getDefaultCategories = () => DEFAULT_CATEGORIES

export const getEventCategories = async (client) => {
  try {
    const result = await client.query({
      query: GET_CATEGORIES,
      variables: { categoryType: 'EVENT', categoryEnableType: 'ENABLED' },
    })
    return result.data.categories
  } catch (error) {
    return DEFAULT_CATEGORIES
  }
}
