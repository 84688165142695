import React, { PureComponent } from 'react'
import axios from 'axios'
import Lottie, { LottieAnimationItem } from 'lottie-web'

import { imageUrl } from '@libs/appUrl'

import cx from 'classnames'
import styles from './index.scss'

class LottieSVG extends PureComponent {
  static defaultProps = {
    wrapClassName: 'default',
    className: 'heart',
    animationData: {},
    path: '',
    loop: true,
    autoplay: true,
    name: '',
    renderer: 'svg',
    preserveAspectRatio: 'xMinYMin slice',
    // customer
    speed: 1, // 1+: more fast / 0: normal[base:1] / -1: more fast with -direction
    direction: 1, // -1, 1
    //
    defaultStartFrame: 0, // default start frame
    startFromLastFrame: false, // [true]: start from last frame, [false]: start from 0 frame
    // events
    onComplete: () => {},
    onLoopComplete: () => {},
  }

  constructor(props) {
    super(props)
    this.animation = LottieAnimationItem || null
    this.state = {
      isProgress: false,
    }
  }

  refContainer = (container) => {
    if (!container) return
    this.container = container
  }

  componentDidMount() {
    this.initialLottie().catch((exception) => console.warn(exception))
  }

  componentWillUnmount() {
    if (!this.animation || this.state.isProgress) return
    this.destroy() // make sure unmount will destroy self and release memory.
  }

  initialLottie = async () => {
    const {
      className,
      renderer,
      path,
      loop,
      autoplay,
      preserveAspectRatio,
      viewBoxSize,
      // customer
      speed,
      direction,
      // frame setting
      defaultStartFrame,
      startFromLastFrame,
      // events
      onComplete,
      onLoopComplete,
    } = this.props

    const animationDataUrl = path || `/statics/lottie/${className}.json`

    const animationData = await axios
      .get(animationDataUrl)
      .then((response) => response.data)
      .catch(() => null)

    const loadAnimation = {}
    if (animationData) {
      loadAnimation.animationData = animationData
    } else {
      loadAnimation.path = animationDataUrl
    }

    this.animation = Lottie.loadAnimation({
      ...loadAnimation,
      loop,
      autoplay,
      name: '',
      container: this.container,
      renderer,
      rendererSettings: {
        className: `svg-${className}`,
        preserveAspectRatio, // Supports the same options as the svg element's preserveAspectRatio property
        viewBoxSize,
      },
    })

    // setSpeed
    this.animation.setSpeed(speed)

    // setDirection
    this.animation.setDirection(direction)

    // startFromLastFrame
    if (startFromLastFrame) {
      this.goToAndStop(this.animation.getDuration(true), true)
    } else {
      this.goToAndStop(defaultStartFrame, true)
    }

    // reset autoplay
    if (autoplay) {
      this.goToAndPlay(defaultStartFrame, true) // autoplay
    }

    // onComplete
    if (onComplete) {
      this.animation.addEventListener('complete', () => {
        onComplete(this) // animation load complete.
      })
    }

    // onLoopComplete
    if (onLoopComplete) {
      this.animation.addEventListener('loopComplete', () => {
        onLoopComplete(this) // animation loop complete.
      })
    }
  }

  play() {
    if (!this.animation || this.state.isProgress) return
    this.setState((state) => ({ ...state, isProgress: true }))
    this.animation.play()
  }

  stop() {
    this.setState((state) => ({ ...state, isProgress: false }))
    if (!this.animation) return
    this.animation.stop()
  }

  pause() {
    this.setState((state) => ({ ...state, isProgress: false }))
    if (!this.animation) return
    this.animation.pause()
  }

  destroy() {
    if (!this.animation) return
    this.animation.destroy()
    this.animation = null
  }

  goToAndPlay(startFrame = 0, isFrame = true) {
    if (!this.animation || this.state.isProgress) return
    this.setState((state) => ({ ...state, isProgress: true }))
    const { defaultStartFrame } = this.props
    this.animation.goToAndPlay(startFrame || defaultStartFrame, isFrame)
  }

  goToAndStop(stopFrame = 0, isFrame = true) {
    this.setState((state) => ({ ...state, isProgress: false }))
    if (!this.animation) return
    const { startFromLastFrame, defaultStartFrame } = this.props
    this.animation.goToAndStop(
      stopFrame || startFromLastFrame ? this.animation.getDuration(true) : defaultStartFrame,
      isFrame,
    )
  }

  render() {
    const { wrapClassName } = this.props
    const classNames = cx('default', wrapClassName)
    return (
      <div ref={this.refContainer} className={classNames}>
        <style jsx>{styles}</style>
      </div>
    )
  }
}

export default LottieSVG
