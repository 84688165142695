import React, { useMemo } from 'react'
import NextHead from 'next/head'
import { publicUrl, rssUrl } from '@libs/appUrl'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import getNewsArticle from '@libs/getNewsArticle'
import { useTranslation, i18n } from '@i18n'
import { getCoverImage } from '@features/post/libs/postTools'

const getKeywords = (posts, resultLength: number) => {
  const keywords = []
  let i = 0
  while (i < posts.length && keywords.length < resultLength) {
    // 如果是官方活動有可能會沒有 hashTags 與 plainTextContent
    const hashTags = posts[i++]?.hashTags ?? []

    keywords.push(...hashTags)
  }

  return keywords
}

function IndexHead({ activeEvent }) {
  const { pathname } = useRouter()
  const { t } = useTranslation('nav')
  const type = useSelector<any, string>((state) => state.posts.getIn(['posts', 'type']))
  const clas = useSelector<any, string>((state) => state.posts.getIn(['posts', 'clas']))
  const postsImmutable = useSelector<any, any>((state) =>
    state.posts.getIn(['posts', 'list']).filter((post) => !post.get('clas').includes('ad')),
  )

  const path = [type, clas].join('/')
  const url = publicUrl(path)

  const { newsArticle, title, description } = useMemo(() => {
    const posts = postsImmutable.toJS()
    const newestPost = posts[0]
    const authorName = (newestPost?.author || newestPost?.userInfo?.name) ?? t('defaultAuthorName')
    const publisherName = t('common:publisherName', { locales: t(`${i18n.language}`) })

    const keywords =
      type === 'website'
        ? (t(`website.${clas}.keywords`, { returnObjects: true }) as string[])
        : getKeywords(posts, 6)

    const categoryName =
      activeEvent?.name || t(`${clas}.${clas === 'press' ? 'duplicateName' : 'name'}`)
    const shortDescription = activeEvent ? '' : t(`${clas}.shortDescription`)
    const title = t(`metaTitle`, { categoryName, shortDescription })
    const description = activeEvent ? '' : t(`${clas}.description`)

    const coverImage = getCoverImage(newestPost)
    const newsArticle = getNewsArticle({
      headline: title,
      description,
      articleBody: description,
      mainEntityOfPage: url,
      datePublished: new Date(newestPost?.t ?? Date.now()).toISOString(),
      dateModified: new Date(newestPost?.ut ?? Date.now()).toISOString(),
      keywords,
      thumbnailUrl: coverImage.url,
      image: coverImage,
      publisher: { name: publisherName, url },
      author: { name: authorName },
    })

    return { newsArticle, title, description }
  }, [activeEvent, postsImmutable.first(), type, clas])

  return (
    <NextHead>
      <link rel="canonical" href={url} />
      <link rel="alternate" type="application/rss+xml" href={rssUrl(path)} />
      <script
        key="NewsArticle"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(newsArticle) }}
      />
      {pathname !== '/' && <title key="title">{title}</title>}
      {pathname !== '/' && <meta key="description" name="description" content={description} />}
    </NextHead>
  )
}

export default IndexHead
