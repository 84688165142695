import { useContext } from 'react'

import {
  trackWebHomepageECPromotionEnterLinkEvent,
  trackWebHomepageECPromotionEnterPopselectProducts,
} from '@libs/eventTracking/homePage'

import {
  trackWebCategoryECPromotionEnterLinkEvent,
  trackWebCategoryECPromotionEnterPopselectProducts,
} from '@libs/eventTracking/category'

import { ExplorePageContext } from '../index'
import { useSelector } from 'react-redux'
import { Product } from '@features/embed/commodityLink/types'

const useCommodityModel = (commodityModelInfo) => {
  const { isHomePage } = useContext(ExplorePageContext)
  const postsCategory = useSelector<any, any>((state) => state.posts.getIn(['posts', 'clas']))
  const handleSeeMoreOnClick = (e) => {
    e.preventDefault()
    if (isHomePage) {
      trackWebHomepageECPromotionEnterLinkEvent({
        toPageUrl: commodityModelInfo.categoryUrl,
        title: commodityModelInfo.title,
      })
    } else {
      trackWebCategoryECPromotionEnterLinkEvent({
        toPageUrl: commodityModelInfo.categoryUrl,
        title: commodityModelInfo.title,
        category: postsCategory,
      })
    }
    window.open(commodityModelInfo.categoryUrl)
  }

  const handlePopselectProductOnClick = (product: Product) => {
    if (isHomePage) {
      trackWebHomepageECPromotionEnterPopselectProducts({
        toPageUrl: product.url,
        title: commodityModelInfo.title,
      })
    } else {
      trackWebCategoryECPromotionEnterPopselectProducts({
        toPageUrl: product.url,
        title: commodityModelInfo.title,
        category: postsCategory,
      })
    }
  }

  return {
    handleSeeMoreOnClick,
    handlePopselectProductOnClick,
  }
}

export default useCommodityModel
