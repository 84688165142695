import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { PageName } from '@app-core/eventTracking/PageName'

interface TrackWebCategoryArticleListEnterUserProfileEvent {
  userMid: string
  category: string
  toPageUrl: string
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/web-category/web-category.article-list.enter-user-profile.md)
 */
export function trackWebCategoryArticleListEnterUserProfileEvent({
  userMid,
  category,
  toPageUrl,
}: TrackWebCategoryArticleListEnterUserProfileEvent) {
  getEventTracker().track({
    name: WebEventNames.WEB_CATEGORY_ARTICLE_LIST_ENTER_USER_PROFILE,
    properties: {
      currentPage: getCurrentPagePropertyFor(PageName.WEB_CATEGORY),
      toPage: getNextPagePropertyFor({
        pageUrl: toPageUrl,
        pageName: PageName.USER_PROFILE,
      }),
      actionTarget: userMid,
      category,
    },
  })
}
