import React, { ReactNode } from 'react'
import tw from 'twin.macro'

interface MobileDisplayContainerProps {
  children: ReactNode
}

const MobileDisplayContainer = ({ children }: MobileDisplayContainerProps) => {
  return <div css={[tw`md:hidden`]}>{children}</div>
}

export default MobileDisplayContainer
