import React from 'react'
import { useTranslation } from '@i18n'
import tw, { styled, theme } from 'twin.macro'
import { useRouter } from 'next/router'

const ContextBox = styled.div`
  margin: 0 auto;
  padding: 16px 16px 12px;
  ${tw` md:p-0`};

  @media (min-width: ${theme`screens.md`}) {
    max-width: 720px;
    margin: 48px auto;
  }
  @media (min-width: ${theme`screens.lg`}) {
    max-width: 960px;
    margin: 48px auto;
  }
`

const Title = styled.h1`
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 4px;
  @media (min-width: ${theme`screens.md`}) {
    color: #333333;
    font-size: 28px;
  }
`
const Description = styled.div`
  color: #666666;
  font-weight: normal;
  font-size: 14px;
  @media (min-width: ${theme`screens.md`}) {
    color: #999999;
    font-weight: 400;
    font-size: 16px;
  }
`

const ExploreTitleBar = (props) => {
  const { category, activeEvent } = props
  const { pathname } = useRouter()
  const { t } = useTranslation('nav')

  if (pathname === '/') return null

  const title = t('endsWithBoard', {
    name:
      activeEvent?.name || // 活動專欄
      t(`${category}.name`), // 系統分類
  })
  const description = activeEvent
    ? '' // 活動專欄
    : t(`${category}.shortDescription`) // 系統分類

  return (
    <ContextBox>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </ContextBox>
  )
}

export default ExploreTitleBar
