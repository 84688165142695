export const GET_LIST_SUCCESS = 19001 // 文章列表取得
export const LIKE_SUCCESS = 19002
export const GET_POST_SUCCESS = 19003 // 文章取得成功
export const GET_REPLY_SUCCESS = 19007 // 取得回覆成功
export const SUBMIT_COMMENT_SUCCESS = 18003 // 評論送出成功
export const EDIT_COMMENT_SUCCESS = 18004 // 評論編輯成功
export const DELETE_COMMENT_SUCCESS = 18005 // 評論編輯成功
export const REPORT_COMMENT_SUCCESS = 19008 // 檢舉評論成功

export const TOKEN_CORRECT = 12000 // Token 正確

export const GET_GUEST_TOKEN_SUCCESS = 11002 // 訪客token取得
export const GUEST_TOKEN_SUCCESS = 12001 // Guest Token 正確
// User
export const USERINFO_GET_SUCCESS = 15002 // 使用者資料取得成功
export const USER_FOLLOW_UNFOLLOW = 15006 // followUser/unfollowUsert成功

export const SUCCESS_POST_DELETE = 18006 //刪除貼文成功
export const SUCCESS_REPLY_DELETE = 18005 //刪除評論成功
