import React, { PureComponent } from 'react'

import LottieSVG from '@components/LottieSVG'

import { compose, withTranslation } from '@hocs'
import { dispatchCustomEvent, LikeUIEvents } from '@libs/customEvents'
import { LikeActionType } from '@libs/eventTracking/article'
import { TrackingSectionContext } from '@features/post/contexts/TrackingSectionContext'
import cx from 'classnames'
import styles from './index.scss'

class Like extends PureComponent {
  static defaultProps = {
    className: '',
    loop: false,
    isLike: false,
    likeNum: 0,
    postBottom: false,
    onClickLike: () => false,
    t: () => false,
  }
  static contextType = TrackingSectionContext

  state = { display: false }

  onLikeClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { isLike, onClickLike } = this.props
    this.setState({ display: !isLike })
    if (onClickLike) onClickLike()
  }

  onLottieComplete = (lottie) => {
    lottie && lottie.destroy() // 參考原先popdaily.server的做法 撥放後自動銷毀 避免使用觸碰到SVG的大動畫而無法點擊文章
    this.setState({ display: false })
  }

  render() {
    const { className, loop = false, isLike = false, likeNum = 0, postBottom, t } = this.props
    const active = isLike || loop
    const isRender = (isLike && this.state.display) || loop
    const classNames = cx('like-container', className.split(' '), { postBottom })

    return (
      <div className={classNames} onClick={this.onLikeClick} data-isclicked={isLike ? 1 : 0}>
        <style jsx>{styles}</style>
        <div className="like-num">{likeNum >= 0 ? likeNum : 0}</div>
        {postBottom && <div className="like-char">{t('post:讚')}</div>}
        <div className={cx('like-icon', { 'like-icon-active': active })}>
          {isRender && (
            <LottieSVG
              wrapClassName="lottie-container"
              className="heart"
              loop={loop}
              autoplay={true}
              defaultStartFrame={33}
              startFromLastFrame={true}
              onComplete={this.onLottieComplete}
            />
          )}
        </div>
      </div>
    )
  }
}

export default compose(withTranslation())(Like)
