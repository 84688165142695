import React from 'react'
import styled from 'styled-components'
import Like from '@components/Like'
import { connect } from 'react-redux'
import tw from 'twin.macro'
import useGetArticleCardInfo from './useGetArticleCardInfo'
import ImageWithFallback from '@components/ImageWithFallback'
import PopImage from '@components/PopImage'
interface CoverImageContainerProps {
  isFirstArticle: boolean
}
const CoverImageContainer = styled.div<CoverImageContainerProps>`
  padding-bottom: 52%;
  position: relative;
  margin-top: ${(props) => (props.isFirstArticle ? 0 : 10)}px;
  margin-bottom: 10px;
`

const CoverImage = styled(PopImage)`
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const TitleContainer = styled.div`
  ${tw`font-semibold text-article text-gray-800`}
  margin: 0px 16px;
  margin-top: 10px;
  margin-bottom: 4px;
`

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const UserNameAndPublishedDateContainer = styled.div`
  height: 40px;
`

const UserNameContainer = styled.div`
  height: 20px;
  line-height: 20px;
`

const PublishedDateContainer = styled.time`
  display: block;
  height: 20px;
  line-height: 20px;
`

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;
`

const Avatar = styled(ImageWithFallback)`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 16px;
`

const LikeContainer = styled.div`
  margin-left: auto;
  margin-right: 4px;
`

const MobileExploreArticleCard = (props) => {
  const { article, dispatch, index } = props

  const {
    handleClickLike,
    userInfo: { name: userName },
    postID,
    coverImage,
    dateTime,
    formatted,
    isLike,
    like,
    title,
    avatar,
    postLink,
    avatarImageFallbackSrc,
    handleArticleClick,
    handleUserClick,
  } = useGetArticleCardInfo({ article, dispatch })

  const isFirstArticle = index === 0
  return (
    <a onClick={handleArticleClick} href={postLink}>
      <CoverImageContainer isFirstArticle={isFirstArticle}>
        <CoverImage
          noShaper={coverImage.fallback}
          imageUrl={coverImage.thumb}
          sizes="100vw"
          ratio={0.52}
        />
      </CoverImageContainer>
      <div>
        <TitleContainer>{title}</TitleContainer>
        <FooterContainer>
          <UserInfoContainer onClick={handleUserClick}>
            <Avatar src={avatar} alt="error" fallbackSrc={avatarImageFallbackSrc} />
            <UserNameAndPublishedDateContainer>
              <UserNameContainer tw="text-gray-600 text-h6">{userName}</UserNameContainer>
              <PublishedDateContainer tw="text-gray-400 text-h6" dateTime={dateTime}>
                {formatted}
              </PublishedDateContainer>
            </UserNameAndPublishedDateContainer>
          </UserInfoContainer>
          <LikeContainer>
            <Like id={postID} likeNum={like} isLike={isLike} onClickLike={handleClickLike} />
          </LikeContainer>
        </FooterContainer>
      </div>
    </a>
  )
}

export default connect(null)(MobileExploreArticleCard)
