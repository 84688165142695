import React, { useEffect } from 'react'

/**
 * 探索頁演算法推薦模組廣告
 *
 * Ad unit = `All_native_1, All_native_2, All_native_3, All_native_4`
 */
export default function ExploreArticleAd(props) {
  const { adId, eventName } = props
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: eventName, exploreArticleAdId: adId })
  }, [])
  return <div id={adId} style={{ marginTop: '15px' }} />
}
