import { Product } from './types'
import { useRouter } from 'next/router'

const useTrackEvent = () => {
  const {
    query: { postID },
  } = useRouter()

  const trackImpressionEvent = (products: Product[]) => {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'commodityLinkCardImpression',
      ecommerce: {
        impressions: products.map((product, index) => {
          return {
            name: product.name,
            price: product.consumerPrice,
            brand: product.brand,
            id: product.sku,
            position: index + 1,
            list: `Article - ${postID}`,
          }
        }),
      },
    })
  }

  const trackClickEvent = (product: Product) => {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'commodityLinkCardClick',
      ecommerce: {
        click: {
          actionField: { list: `Article - ${postID}` },
          products: [
            {
              name: product.name,
              price: product.consumerPrice,
              brand: product.brand,
              id: product.sku,
            },
          ],
        },
      },
    })
  }

  return { trackImpressionEvent, trackClickEvent }
}

export default useTrackEvent
