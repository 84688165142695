import { getEventTracker } from '@libs/eventTracking'
import {
  getFromPagePropertyFor,
  getCurrentPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://www.dropbox.com/scl/fi/mcdyh4u2nwnkwilsu45ad/%E3%80%90%E6%95%B8%E6%93%9A%E3%80%91Web-%E9%A6%96%E9%A0%81-v2.paper?dl=0&rlkey=ppfu2llmc4gocre9kynovap5a#:uid=903631422140776544431959&h2=Sprint-41)
 */
export function trackWebHomePageViewEvent(input) {
  const { pageName } = input.currentPage
  getEventTracker().track({
    name: WebEventNames.WEB_HOMEPAGE_VIEW,
    properties: {
      fromPage: getFromPagePropertyFor(input.fromPage),
      currentPage: getCurrentPagePropertyFor(pageName),
    },
  })
}
