import { ChevronRightIcon } from '@components/Icons'
import CarouselCommodityLink from '@features/embed/commodityLink/CarouselCommodityLink'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import useCommodityModel from './useCommodityModel'

interface ProductModelContainerProps {
  rowStart: number
}

const Title = styled.div`
  ${tw`text-h3 text-gray-800 font-semibold`}
`
const Description = styled.div`
  ${tw`text-h6 text-gray-800`}
  margin-top: 12px;
`
const SeeMoreContainer = styled.a`
  ${tw`text-h6 text-primary-indigo`}
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SeeMoreText = styled.span`
  display: inline-block;
  margin-right: 4px;
`

const CommodityModelContainer = styled.div<ProductModelContainerProps>`
  margin: 60px 0px;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: ${(props) => props.rowStart};
  grid-row-end: ${(props) => props.rowStart + 1};
`

const DesktopCommodityModel = ({ commodityModelInfo }) => {
  const { handlePopselectProductOnClick, handleSeeMoreOnClick } =
    useCommodityModel(commodityModelInfo)

  return (
    <CommodityModelContainer rowStart={commodityModelInfo.rowStart}>
      <Title>{commodityModelInfo.title}</Title>
      <Description>{commodityModelInfo.description}</Description>
      {commodityModelInfo.categoryUrl && (
        <SeeMoreContainer href={commodityModelInfo.categoryUrl} onClick={handleSeeMoreOnClick}>
          <SeeMoreText>看更多</SeeMoreText> <ChevronRightIcon width={16} height={16} />
        </SeeMoreContainer>
      )}
      <CarouselCommodityLink
        products={commodityModelInfo.products}
        onProductClick={handlePopselectProductOnClick}
      />
    </CommodityModelContainer>
  )
}

export default DesktopCommodityModel
