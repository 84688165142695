import React from 'react'
import Link from 'next/link'
import tw from 'twin.macro'

const A = tw.a`text-primary-indigo border-b hover:border-primary-indigo pb-1`

export function IndexLink({ children }) {
  return (
    <Link href="/">
      <A>{children}</A>
    </Link>
  )
}
