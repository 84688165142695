import React from 'react'
import styled from 'styled-components'
import Like from '@components/Like'
import { connect } from 'react-redux'
import tw from 'twin.macro'
import useGetArticleCardInfo from './useGetArticleCardInfo'
import PopImage from '@components/PopImage'
import ImageWithFallback from '@components/ImageWithFallback'

const CoverImageContainer = styled.div`
  position: relative;
  margin: 10px 0px;
  overflow: hidden;
  border-radius: 8px;
`

const CoverImage = styled(PopImage)`
  transition: transform 0.15s ease-in-out;
  padding-bottom: 52%;
`

const TitleContainer = styled.div`
  ${tw`font-semibold text-h6  text-gray-800`}
  margin-top: 10px;
  margin-bottom: 8px;
  // 參考 web/src/components/MasonryVirtualized/index.scss 設定 max-width
  @media (min-width: 992px) {
    ${tw`text-article`}
  }
`

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled(ImageWithFallback)`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-left: 2px;
  border-radius: 16px;
`

const LikeContainer = styled.div`
  margin-left: auto;
  margin-right: 4px;
`

const ArticleCardContainer = styled.a`
  &:hover {
    ${CoverImage} {
      transform: scale(1.1, 1.1);
    }
  }
`

const DesktopExploreArticleCard = (props) => {
  const { article, dispatch } = props

  const {
    handleClickLike,
    userInfo: { name: userName },
    postID,
    coverImage,
    dateTime,
    formatted,
    isLike,
    like,
    title,
    avatar,
    postLink,
    avatarImageFallbackSrc,
    handleArticleClick,
    handleUserClick,
  } = useGetArticleCardInfo({ article, dispatch })

  return (
    <ArticleCardContainer onClick={handleArticleClick} href={postLink}>
      <CoverImageContainer>
        <CoverImage
          noShaper={coverImage.fallback}
          imageUrl={coverImage.thumb}
          sizes={`(min-width: 992px): calc(${960 - 30}px / 3), calc(${720 - 30}px / 3)`}
          ratio={0.52}
        />
      </CoverImageContainer>
      <TitleContainer>{title}</TitleContainer>
      <FooterContainer>
        <UserInfoContainer onClick={handleUserClick} tw="flex">
          <Avatar src={avatar} fallbackSrc={avatarImageFallbackSrc} />
          <div>
            <div tw="text-gray-600 text-h6">{userName}</div>
            <time tw="text-gray-400 text-h6" dateTime={dateTime}>
              {formatted}
            </time>
          </div>
        </UserInfoContainer>
        <LikeContainer>
          <Like id={postID} likeNum={like} isLike={isLike} onClickLike={handleClickLike} />
        </LikeContainer>
      </FooterContainer>
    </ArticleCardContainer>
  )
}

export default connect(null)(DesktopExploreArticleCard)
