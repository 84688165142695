import DesktopExploreArticleCard from './DesktopExploreArticleCard'
import MobileExploreArticleCard from './MobileExploreArticleCard'
import React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import tw from 'twin.macro'
import { DesktopDisplayContainer, MobileDisplayContainer } from '@components/DeviceContainer'
import ExploreArticleAd from './ExploreArticleAd'
import { MobileCommodityModel, DesktopCommodityModel } from '../CommodityModel'
import { topArticle } from './toTop'

// 參考 web/src/components/MasonryVirtualized/index.scss 設定 max-width
// TODO: 等大輪播上線後做調整
const DesktopGridContainer = styled.div`
  ${tw`grid grid-cols-3 gap-4`}
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 960px;
  }
`

const MobileGridContainer = styled.div`
  ${tw`grid grid-cols-1 gap-4`}
`

const ArticleCards = (props) => {
  const { webArticles, mobileArticles } = props
  // console.log(webArticles)
  const router = useRouter()

  const { query } = router

  return (
    <>
      <DesktopDisplayContainer>
        <DesktopGridContainer>
          {/* 置頂文章 */}
          {query.clas === '2023newyear' && <DesktopExploreArticleCard article={topArticle} />}

          {webArticles &&
            webArticles.map((article) => {
              if (article.isAd) {
                return (
                  <ExploreArticleAd
                    key={article.adId}
                    adId={article.adId + 'desktop'}
                    eventName={article.eventName}
                  />
                )
              }
              if (article.__typename === 'PopSelectProduct') {
                return <DesktopCommodityModel key={article.id} commodityModelInfo={article} />
              }
              return article.postID !== 'love.1365198' ? (
                <DesktopExploreArticleCard key={article.postID} article={article} />
              ) : null
            })}
        </DesktopGridContainer>
      </DesktopDisplayContainer>
      <MobileDisplayContainer>
        <MobileGridContainer>
          {/* 置頂文章 */}
          {/* 如果要刪除置頂文章，請記得將下方 MobileExploreArticleCard 的 index 改成 index={index} */}
          {query.clas === '2023newyear' && (
            <MobileExploreArticleCard article={topArticle} index={0} />
          )}
          {mobileArticles &&
            mobileArticles
              .filter((article) => !article.isAd)
              .map((article, index) => {
                if (article.__typename === 'PopSelectProduct') {
                  return <MobileCommodityModel key={article.id} commodityModelInfo={article} />
                }
                return article.postID !== 'love.1365198' ? (
                  <MobileExploreArticleCard
                    key={article.postID}
                    article={article}
                    index={index + 1}
                  />
                ) : null
              })}
        </MobileGridContainer>
      </MobileDisplayContainer>
    </>
  )
}

export default ArticleCards
