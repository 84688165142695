import { WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getFromPagePropertyFor,
  FromPagePropertyForInputType,
} from '@libs/eventTracking/eventPropertyUtils'
import { PageName } from '@app-core/eventTracking/PageName'

export interface TrackWebCategoryViewEventInput {
  category: string
  fromPage: FromPagePropertyForInputType
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/web-category/web-category.view.md)
 */
export function trackWebCategoryViewEvent({
  category,
  fromPage,
}: TrackWebCategoryViewEventInput): void {
  getEventTracker().track({
    name: WebEventNames.WEB_CATEGORY_VIEW,
    properties: {
      currentPage: getCurrentPagePropertyFor(PageName.WEB_CATEGORY),
      fromPage: getFromPagePropertyFor(fromPage),
      category,
    },
  })
}
