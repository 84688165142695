import { createContext } from 'react'

export enum TrackingSection {
  ARTICLE_INFO = 'article.info',
  ARTICLE_AUTHOR_INFO = 'article.author-info',
}

/**
 * - 事件追蹤會根據同頁面但不同板塊／區塊埋放
 * - UI 事件需要帶此資訊
 */
export const TrackingSectionContext = createContext({
  section: null,
})
