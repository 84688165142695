import appUrl, { imageUrl } from '@libs/appUrl'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import qs from 'qs'
import { trackWebHomePageSlideEnterArticleEvent } from '@libs/eventTracking/homePage/web-homepage.slide-show.enter-article'
import PopImage from '@components/PopImage'

const getImage = (article) => {
  const { img } = article

  return img[0]
}

const getTitle = (article) => {
  const { type = '', web = {} } = article
  const isWeb = type === 'web' || type === 'pugc'
  const title = isWeb ? web.title : article.title || ''
  return title
}

interface CoverImageProps {
  isCenter: boolean
}

const CoverImage = styled(PopImage)<CoverImageProps>`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-bottom: 52%;
  opacity: ${(props) => (props.isCenter ? '1' : '0.5')};
  transition: transform 0.15s ease-in-out;
`

const TitleShadow = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 148px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
`

const Title = styled.div`
  ${tw`text-h1 font-semibold`}
  position: absolute;
  color: white;
  text-align: center;
  z-index: 10;
  bottom: 28px;
  padding: 0 80px;
  line-height: 135%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const CoverImageContainer = styled.div`
  width: 720px;
  margin: 0 2px;
  position: relative;
  overflow: hidden;
  transition: transform ease-in-out 0.15s;
  @media (min-width: 992px) {
    width: 960px;
  }
  &:hover {
  ${CoverImage} {
    transform: scale(1.1, 1.1);
  }
`

const CarouselCard = (props) => {
  const { article, isCenter } = props
  const { postID } = article
  const postUrl = `${appUrl.myhost}/${postID.replace(/\./g, '/')}`
  const trackQueryString = qs.stringify({
    ...qs.parse(postUrl.split('?')[1]),
    fromPage: {
      pageName: 'web-homepage',
      pageComponent: 'slide-show',
    },
  })
  const postUrlWithTracking = `${postUrl}?${trackQueryString}`
  const coverImage = getImage(article)
  const title = getTitle(article)

  const handleCarouselCardClick = () => {
    const postUrl = `${appUrl.myhost}/${postID.replace(/\./g, '/')}`

    trackWebHomePageSlideEnterArticleEvent({
      currentPage: {
        pageName: 'web-homepage',
      },
      toPage: {
        pageUrl: postUrl,
      },
      actionTarget: postID,
    })
  }

  return (
    <div>
      <a href={postUrlWithTracking} onClick={handleCarouselCardClick}>
        <CoverImageContainer>
          <CoverImage
            imageUrl={imageUrl(coverImage?.path)}
            isCenter={isCenter}
            sizes="960px"
            ratio={0.52}
          />
          <Title>{title}</Title>
          <TitleShadow />
        </CoverImageContainer>
      </a>
    </div>
  )
}

export default CarouselCard
