import { gql } from '@apollo/client'

const GET_POPSELECT_PRODUCT_BY_CLAS = gql`
  query GetPopSelectProductByClas($clas: String!) {
    getPopSelectProductByClas(clas: $clas) {
      __typename
      id
      description
      categoryUrl
      title
      clas
      order
      products {
        id
        brand
        url
        name
        cover
        consumerPrice
        originalPrice
        sku
      }
    }
  }
`

export default GET_POPSELECT_PRODUCT_BY_CLAS
