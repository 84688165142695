import { getEventTracker } from '@libs/eventTracking'
import { WebEventNames } from '@libs/eventTracking/homePage/common'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

export function trackFloatingIconEnterPopBoxEvent(
  currentPageUrl,
  toPageUrl,
) {
  const toPage = {
    pageName: 'popbox',
    pageUrl: toPageUrl,
  }
  getEventTracker().track({
    name: WebEventNames.FLOATING_ICON_ENTER_POPBOX,
    properties: {
      currentPage: getCurrentPagePropertyFor(null, currentPageUrl),
      toPage: getNextPagePropertyFor(toPage),
    },
  })
}
