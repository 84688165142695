import React from 'react'

function isImageValid(src) {
  const promise = new Promise((resolve) => {
    const img = document.createElement('img')
    img.onerror = () => resolve(false)
    img.onload = () => resolve(true)
    img.src = src
  })

  return promise
}

const ImageWithFallback = ({ src, fallbackSrc, ...rest }) => {
  const imgEl = React.useRef(null)
  React.useEffect(() => {
    isImageValid(src).then((isValid) => {
      if (!isValid) {
        imgEl.current.src = fallbackSrc
      }
    })
  }, [src])

  return <img {...rest} ref={imgEl} src={src} />
}

export default ImageWithFallback
