import React, { ReactNode } from 'react'
import tw from 'twin.macro'

interface DesktopDisplayContainerProps {
  children: ReactNode
}

const DesktopDisplayContainer = ({ children }: DesktopDisplayContainerProps) => {
  return <div css={[tw`hidden md:block`]}>{children}</div>
}

export default DesktopDisplayContainer
