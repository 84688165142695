import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { PageName } from '@app-core/eventTracking/PageName'

interface TrackWebCategoryBannerEnterAd {
  toPageUrl: string
  category: string
}

export function trackWebCategoryBannerEnterAd({
  toPageUrl,
  category,
}: TrackWebCategoryBannerEnterAd) {
  getEventTracker().track({
    name: WebEventNames.WEB_CATEGORY_BANNER_ENTER_AD,
    properties: {
      currentPage: getCurrentPagePropertyFor(PageName.WEB_CATEGORY),
      toPage: getNextPagePropertyFor({
        pageUrl: toPageUrl,
      }),
      actionTarget: toPageUrl,
      category,
    },
  })
}
