import constantCreator from '../libs/constantCreator'
import * as ApiResponseCode from '../libs/ApiResponseCode'
import { v1PostWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('app')

export const CLOSE_R18_ALERT = createConst('CLOSE_R18_ALERT')
export const closeR18Alert = () => {
  return {
    type: CLOSE_R18_ALERT,
  }
}

export const OPEN_LOGIN_ALERT = createConst('OPEN_LOGIN_ALERT')
export const CLOSE_LOGIN_ALERT = createConst('CLOSE_LOGIN_ALERT')
export const OPEN_TOPIC_LIST = createConst('OPEN_TOPIC_LIST')
export const CLOSE_TOPIC_LIST = createConst('CLOSE_TOPIC_LIST')
export const SET_REPLY_ID = createConst('SET_REPLY_ID')
export const OPEN_REPLY_BAR = createConst('OPEN_REPLY_BAR')
export const CLOSE_REPLY_BAR = createConst('CLOSE_REPLY_BAR')
export const OPEN_REPORT = createConst('OPEN_REPORT')
export const CLOSE_REPORT = createConst('CLOSE_REPORT')

export const openLoginAlert = () => {
  return {
    type: OPEN_LOGIN_ALERT,
  }
}

export const closeLoginAlert = () => {
  return {
    type: CLOSE_LOGIN_ALERT,
  }
}

export const openTopicList = () => {
  return {
    type: OPEN_TOPIC_LIST,
  }
}

export const closeTopicList = () => {
  return {
    type: CLOSE_TOPIC_LIST,
  }
}

export const setReplyId = (replyId = 0) => {
  return {
    type: SET_REPLY_ID,
    payload: { replyId },
  }
}

export const openReplyBar = () => {
  return {
    type: OPEN_REPLY_BAR,
  }
}

export const closeReplyBar = () => {
  return {
    type: CLOSE_REPLY_BAR,
  }
}

export const openReport = () => {
  return {
    type: OPEN_REPORT,
  }
}

export const closeReport = () => {
  return {
    type: CLOSE_REPORT,
  }
}

export const OPEN_REPLY_POP = createConst('OPEN_REPLY_POP')
export const CLOSE_REPLY_POP = createConst('CLOSE_REPLY_POP')

export const openReplyPop = ({ post, replyID = -1, isCurrentUserComment = false }) => {
  return {
    type: OPEN_REPLY_POP,
    post,
    replyID,
    isCurrentUserComment,
  }
}

export const closeReplyPop = () => {
  return {
    type: CLOSE_REPLY_POP,
  }
}

export const OPEN_SHARE_POP = createConst('OPEN_SHARE_POP')
export const CLOSE_SHARE_POP = createConst('CLOSE_SHARE_POP')

export const openSharePop = ({ url, post, replyID = -1, isCurrentUserComment = false }) => {
  return {
    type: OPEN_SHARE_POP,
    url,
    post,
    replyID,
    isCurrentUserComment,
  }
}

export const closeSharePop = () => {
  return {
    type: CLOSE_SHARE_POP,
  }
}

export const OPEN_MESSAGE_DIALOG = createConst('OPEN_MESSAGE_DIALOG')
export const CLOSE_MESSAGE_DIALOG = createConst('CLOSE_MESSAGE_DIALOG')

export const openMessageDialog = ({ title, message }) => {
  return {
    type: OPEN_MESSAGE_DIALOG,
    title,
    message,
  }
}

export const closeMessageDialog = () => {
  return {
    type: CLOSE_MESSAGE_DIALOG,
  }
}

export const REPORT_POST = createConst('REPORT_POST')
export const REPORT_POST_SUCCESS = createConst('REPORT_POST_SUCCESS')
export const REPORT_POST_FAILED = createConst('REPORT_POST_FAILED')

export const reportPost = ({ userToken: token, post }) => {
  const { postID, type: postType } = post

  return async (dispatch, _getState, { alert }) => {
    dispatch({ type: REPORT_POST })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/post/report',
        { token, postID, postType },
        userToken,
      )
      dispatch({ type: REPORT_POST_SUCCESS, response })
      if (response.code === ApiResponseCode.REPORT_COMMENT_SUCCESS) {
        alert(response.msg)
      }
    } catch (error) {
      dispatch({ type: REPORT_POST_FAILED, error })
    }
  }
}

export const REPORT_REPLY = createConst('REPORT_REPLY')
export const REPORT_REPLY_SUCCESS = createConst('REPORT_REPLY_SUCCESS')
export const REPORT_REPLY_FAILED = createConst('REPORT_REPLY_FAILED')

export const reportReply = ({ postID, replyID }) => {
  return async (dispatch, _getState, { i18n, toast }) => {
    dispatch({ type: REPORT_REPLY })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/reply/report',
        { postID, replyID },
        userToken,
      )
      dispatch({ type: REPORT_REPLY_SUCCESS, response })
      toast(i18n.t('common:檢舉成功'))
    } catch (error) {
      dispatch({ type: REPORT_REPLY_FAILED, error })
    }
  }
}

export const RECALL_POST = createConst('RECALL_POST')
export const RECALL_POST_SUCCESS = createConst('RECALL_POST_SUCCESS')
export const RECALL_POST_FAILED = createConst('RECALL_POST_FAILED')

export const recallPost = ({ postID, via }) => {
  let adminToken
  if (localStorage.getItem('popadmin_token')) {
    adminToken = localStorage.getItem('popadmin_token').split('"').join('')
  }

  return async (dispatch, _getState, { i18n, toast, pageNavigation }) => {
    dispatch({ type: RECALL_POST })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/popadmin/post/del',
        {
          token: adminToken,
          postID,
          via,
        },
        userToken,
      )
      dispatch({ type: RECALL_POST_SUCCESS, response })

      if (response.code === ApiResponseCode.SUCCESS_POST_DELETE) {
        toast(i18n.t('common:下架成功'))
        if (pageNavigation) {
          pageNavigation.reload(false)
        }
      } else {
        toast(i18n.t('common:下架失敗'))
      }
    } catch (error) {
      dispatch({ type: RECALL_POST_FAILED, error })
    }
  }
}

export const RECALL_REPLY = createConst('RECALL_REPLY')
export const RECALL_REPLY_SUCCESS = createConst('RECALL_REPLY_SUCCESS')
export const RECALL_REPLY_FAILED = createConst('RECALL_REPLY_FAILED')

export const recallReply = ({ postID, replyID }) => {
  return async (dispatch, _getState, { i18n, toast }) => {
    dispatch({ type: RECALL_REPLY })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/popadmin/reply/del',
        { postID, replyID },
        userToken,
      )
      dispatch({ type: RECALL_REPLY_SUCCESS, response })

      if (response.code === ApiResponseCode.SUCCESS_REPLY_DELETE) {
        toast(i18n.t('common:下架成功'))
      } else {
        toast(i18n.t('common:下架失敗'))
        // console.log('response', response)
      }
    } catch (error) {
      dispatch({ type: RECALL_REPLY_FAILED, error })
    }
  }
}

export const SET_IS_APP = createConst('SET_IS_APP')
export const setIsApp = ({ isApp }) => (dispatch) => {
  dispatch({ type: SET_IS_APP, isApp })
}

export const OPEN_CONFIRM_DIALOG = createConst('OPEN_CONFIRM_DIALOG')
export const CLOSE_CONFIRM_DIALOG = createConst('CLOSE_CONFIRM_DIALOG')

export const openConfirmDialog = ({
  title,
  content,
  sureButtonTitle,
  cancelButtonTitle,
  onSure,
  afterClose = undefined,
  afterSure = undefined,
}) => {
  return {
    type: OPEN_CONFIRM_DIALOG,
    title,
    content,
    onSure,
    sureButtonTitle,
    cancelButtonTitle,
    afterClose,
    afterSure,
  }
}

export const closeConfirmDialog = () => {
  return {
    type: CLOSE_CONFIRM_DIALOG,
  }
}

//=== for app ===
export const SET_APP_STATE = createConst('SET_APP_STATE')
export function setAppState({ appState }) {
  return {
    type: SET_APP_STATE,
    appState,
  }
}

export const SET_EVENT_EMITTER = createConst('SET_EVENT_EMITTER')
export function setEventEmitter({ eventEmitter }) {
  return {
    type: SET_EVENT_EMITTER,
    eventEmitter,
  }
}

export const SET_UNSUBSCRIBE_DYNAMIC_LINK = createConst('SET_UNSUBSCRIBE_DYNAMIC_LINK')
export function setUnsubscribeDynamicLink({ unsubscribeDynamicLink }) {
  return {
    type: SET_UNSUBSCRIBE_DYNAMIC_LINK,
    unsubscribeDynamicLink,
  }
}

export const SET_TRACK_EVENT_QUEUE_TIMER = createConst('SET_TRACK_EVENT_QUEUE_TIMER')
export function setTrackEventQueueTimer({ trackEventQueueTimer }) {
  return {
    type: SET_TRACK_EVENT_QUEUE_TIMER,
    trackEventQueueTimer,
  }
}

export const SET_IS_CLICKED_MAP = createConst('SET_IS_CLICKED_MAP')
export function setIsClickedMap({ isClickedMap }) {
  return {
    type: SET_IS_CLICKED_MAP,
    isClickedMap,
  }
}
