export enum WebEventNames {
  // homepage
  WEB_HOMEPAGE_VIEW = 'web-homepage.view',
  WEB_HOMEPAGE_SLIDE_SHOW_ENTER_ARTICLE = 'web-homepage.slide-show.enter-article',
  WEB_HOMEPAGE_ARTICLE_LIST_ENTER_ARTICLE = 'web-homepage.article-list.enter-article',
  FLOATING_ICON_ENTER_EVENT = 'floating-icon.enter-event',
  FLOATING_ICON_ENTER_POPBOX = 'floating-icon.enter-popbox',
  WEB_HOMEPAGE_EC_PROMOTION_ENTER_LINK = 'web-homepage.ec-promotion.enter-link',
  WEB_HOMEPAGE_EC_PROMOTION_ENTER_POPSELECT_PRODUCTS = 'web-homepage.ec-promotion.enter-popselect-products',
}
