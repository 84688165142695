import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { PageName } from '@app-core/eventTracking/PageName'

interface TrackWebCategoryArticleListEnterArticleEvent {
  article: string
  category: string
  toPageUrl: string
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/web-category/web-category.article-list.enter-article.md)
 */
export function trackWebCategoryArticleListEnterArticleEvent({
  article,
  toPageUrl,
  category,
}: TrackWebCategoryArticleListEnterArticleEvent) {
  getEventTracker().track({
    name: WebEventNames.WEB_CATEGORY_ARTICLE_LIST_ENTER_ARTICLE,
    properties: {
      currentPage: getCurrentPagePropertyFor(PageName.WEB_CATEGORY),
      toPage: getNextPagePropertyFor({
        pageUrl: toPageUrl,
        pageName: PageName.ARTICLE,
      }),
      actionTarget: article,
      category,
    },
  })
}
