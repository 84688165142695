import React, { useRef, useState, useMemo, useEffect } from 'react'
import Slider, { Settings } from 'react-slick'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/Icons'
import { DesktopDisplayContainer } from '@components/DeviceContainer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CarouselCard from './CarouselCard'
import _ from 'lodash'

const CarouselContainer = styled.div`
  width: 100%;
  max-width: ${960 * 3}px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  height: auto;
  margin-top: 36px;
  margin-bottom: 52px;
  padding-bottom: 28px;
  height: 404px;
  @media (min-width: 992px) {
    height: 529px;
  }
`

interface ArrowContainerProps {
  location: 'left' | 'right'
}

const ArrowContainer = styled.div<ArrowContainerProps>`
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  ${(props) =>
    props.location === 'left' ? `margin-left: calc((100% - 720px) / 2 - 40px - 16px);` : ''}
  ${(props) =>
    props.location === 'right'
      ? `margin-right: calc((100% - 720px) / 2 - 40px - 16px);`
      : ''}
  cursor: pointer;
  &:hover > svg * {
    fill: #9999ff;
  }
  @media (min-width: 992px) {
    ${(props) =>
      props.location === 'left' ? `margin-left: calc((100% - 960px) / 2 - 40px - 16px);` : ''}
    ${(props) =>
      props.location === 'right' ? `margin-right: calc((100% - 960px) / 2 - 40px - 16px);` : ''}
  }
`

interface PagingProps {
  isCenter: boolean
}

const Paging = styled.div<PagingProps>`
  ${tw`bg-primary-indigo`}
  ${(props) => (props.isCenter ? '' : 'background-color: #EDEDED;')}
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 5px;
  margin-top: 16px;
`

const DotsContainer = styled.div`
  li {
    width: auto;
    margin: 0;
  }
`

const DesktopCarousel = (props) => {
  const { carouselSlider } = props
  const [currentCardIndex, setCurrentCardIndex] = useState(0)
  const shuffleCarouselSlider = useMemo(() => _.shuffle(carouselSlider), [])

  const sliderSettings: Settings = {
    dots: true,
    infinite: true,
    speed: 650,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    centerMode: true,
    variableWidth: true,
    beforeChange: (_, next) => setCurrentCardIndex(next),
    appendDots: (dots) => <DotsContainer>{dots}</DotsContainer>,
    customPaging: (i) => <Paging isCenter={currentCardIndex === i} />,
  }

  const sliderRef = useRef(null)
  const [isMount, setIsMount] = useState(false)

  const handleArrowMouseOver = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause()
    }
  }

  const handleArrowMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay()
    }
  }

  useEffect(() => {
    setIsMount(true)
  }, [])

  return (
    <DesktopDisplayContainer>
      <CarouselContainer>
        {isMount && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {shuffleCarouselSlider.map((article, index) => (
              <CarouselCard
                key={article.postID}
                article={article}
                isCenter={index === currentCardIndex}
              />
            ))}
          </Slider>
        )}
        <ArrowContainer
          onMouseOver={handleArrowMouseOver}
          onMouseLeave={handleArrowMouseLeave}
          location="left"
          onClick={() => {
            if (sliderRef.current) {
              sliderRef.current.slickPrev()
            }
          }}
        >
          <ArrowLeftIcon />
        </ArrowContainer>
        <ArrowContainer
          onMouseOver={handleArrowMouseOver}
          onMouseLeave={handleArrowMouseLeave}
          location="right"
          onClick={() => {
            if (sliderRef.current) {
              sliderRef.current.slickNext()
            }
          }}
        >
          <ArrowRightIcon />
        </ArrowContainer>
      </CarouselContainer>
    </DesktopDisplayContainer>
  )
}

export default DesktopCarousel
