import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { PageName } from '@app-core/eventTracking/PageName'

interface TrackWebHomepageECPromotionEnterPopselectProducts {
  toPageUrl: string
  title: string
}

export function trackWebHomepageECPromotionEnterPopselectProducts({
  toPageUrl,
  title,
}: TrackWebHomepageECPromotionEnterPopselectProducts) {
  getEventTracker().track({
    name: WebEventNames.WEB_HOMEPAGE_EC_PROMOTION_ENTER_POPSELECT_PRODUCTS,
    properties: {
      currentPage: getCurrentPagePropertyFor(PageName.WEB_HOMEPAGE),
      toPage: getNextPagePropertyFor({
        pageUrl: toPageUrl,
      }),
      title,
      actionTarget: toPageUrl,
    },
  })
}
