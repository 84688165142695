import { useEffect } from 'react'
import Slider from 'react-slick'

import { ArrowLeftIcon, ArrowRightIcon } from '@components/Icons'

import tw, { styled } from 'twin.macro'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Product } from './types'
import useTrackEvent from './useTrackEvent'

type Props = {
  products: Product[]
  onProductClick?: (product: Product) => void
}

const CarouselCommodityLink = (props: Props) => {
  const { products, onProductClick } = props

  const { trackImpressionEvent, trackClickEvent } = useTrackEvent()

  const NextArrow = (props) => {
    const { className, onClick } = props
    const isDisabled = className.indexOf('slick-disabled') !== -1
    return (
      <>
        <div className="linearRight" style={{ display: isDisabled ? 'none' : 'block' }} />
        <div className={className} onClick={onClick}>
          <ArrowRightIcon width={20} height={20} />
        </div>
      </>
    )
  }

  const PrevArrow = (props) => {
    const { className, onClick } = props
    const isDisabled = className.indexOf('slick-disabled') !== -1
    return (
      <>
        <div className="linearLeft" style={{ display: isDisabled ? 'none' : 'block' }} />
        <div className={className} onClick={onClick}>
          <ArrowLeftIcon width={20} height={20} />
        </div>
      </>
    )
  }

  const responsive = products
    .map((_, index) => (index + 1 > 4 ? 4 : index + 1))
    .map((count: number) => {
      const breakpoint = 208 * (count + 1) + 20
      return {
        breakpoint,
        settings: { slidesToShow: count, slidesToScroll: count },
      }
    })

  const numberOfSlides = products.length > 4 ? 4 : products.length

  const settings = {
    slidesToShow: numberOfSlides,
    slidesToScroll: numberOfSlides,
    initialSlide: 0,
    dots: false,
    infinite: false,
    variableWidth: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive,
  }

  useEffect(() => {
    trackImpressionEvent(products)
  }, [])

  const renderCard = (product: Product) => {
    const { id, url, name, cover, originalPrice, consumerPrice } = product
    const onClick = (e) => {
      e.preventDefault()
      trackClickEvent(product)
      if (onProductClick) {
        onProductClick(product)
      }
      window.open(url)
    }

    return (
      <div key={id} style={{ width: 208 }}>
        <Card href={url} target="_top" onClick={onClick}>
          <div className="imageBox">
            <img src={cover} alt="" />
          </div>
          <div className="productName">{name}</div>
          <div className="infoBox">
            <div className="priceBox">
              {originalPrice !== 0 && (
                <div className="originalPrice">
                  <s>NT {originalPrice.toLocaleString()}</s>
                </div>
              )}
              <div className="consumerPrice">
                NT <span className="price">{consumerPrice.toLocaleString()}</span>
              </div>
            </div>
            <div className="cta">去逛逛</div>
          </div>
        </Card>
      </div>
    )
  }

  return (
    <Container>
      <Slider {...settings} className="slick">
        {products.map(renderCard)}
      </Slider>
    </Container>
  )
}

export default CarouselCommodityLink

export const Card = styled.a`
  cursor: pointer;
  height: 295px;
  padding: 8px;
  flex-flow: column;
  background: #ffffff;
  margin: 0 6px;
  border-radius: 12px;
  display: flex;
  border: 1px solid rgb(226, 232, 240);

  &:first-child {
    margin-left: 0;
  }

  .imageBox {
    width: 184px;
    height: 184px;

    img {
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 12px;
    }
  }

  .productName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    width: 184px;
    color: #333333;
    text-overflow: ellipsis;
    margin: 8px 0;
  }

  .infoBox {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
  }

  .priceBox {
    font-size: 14px;
    display: flex;
    flex-flow: column;
    line-height: 21px;

    .originalPrice {
      ${tw`text-gray-400`}
      font-weight: 400;
    }

    .consumerPrice {
      ${tw`text-primary-indigo`}
      font-size: 14px;
      font-weight: 600;

      .price {
        font-size: 18px;
      }
    }
  }

  .cta {
    border-radius: 24px;
    text-align: center;
    ${tw`text-primary-indigo bg-primary-light`}
    font-weight: 500;
    font-size: 14px;
    padding: 8px 12px;
    line-height: 1;
    white-space: nowrap;
  }
`

export const Container = styled.div`
  position: relative;

  .slick-arrow {
    width: 40px;
    height: 40px;
    padding: 10px;

    border-radius: 50%;
    background: #ffffff;
    box-shadow: 1px 1px 5px rgb(221, 221, 224);

    svg {
      color: #333333;
    }
  }

  .slick-prev {
    left: 5px;
    z-index: 2;

    ::before {
      content: initial;
    }
  }

  .slick-next {
    right: 5px;
    z-index: 2;

    ::before {
      content: initial;
    }
  }

  .slick-disabled {
    display: none;
  }

  .slick-list {
    padding: 16px 0;

    &:empty {
      display: none !important;
    }
  }

  .linearLeft {
    width: 50px;
    bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  .linearRight {
    width: 50px;
    bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
`
