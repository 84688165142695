import { useContext } from 'react'
import { useNormalizedPublishedTime } from '@libs/getNormalizedPublishedTime'
import { postLike } from '@app-core/posts/actions'
import appUrl, { imageUrl, publicUrl } from '@libs/appUrl'
import qs from 'qs'
import { ExplorePageContext } from '../index'
import { trackWebHomePageArticleListEnterArticleEvent } from '@libs/eventTracking/homePage/web-homepage.article-list.enter-article'
import { trackWebCategoryArticleListEnterArticleEvent } from '@libs/eventTracking/category/web-category.article-list.enter-article'
import { trackWebCategoryArticleListEnterUserProfileEvent } from '@libs/eventTracking/category/web-category.article-list.enter-user-profile'
import { useSelector } from 'react-redux'

const coverImageFallbackSrc = 'images/articleCardImagePlaceholder.svg'
const avatarImageFallbackSrc = imageUrl('/img/head2.png')

const getTitle = (article) => {
  const { type = '', web = {} } = article
  const isWeb = type === 'web' || type === 'pugc'
  const title = isWeb ? web.title : article.title || ''
  return title
}

const getImage = (article) => {
  const { img } = article

  // 一般文章沒有照片，顯示女孩照片
  if (!(img.length > 0)) {
    return {
      fallback: true,
      w: 1560,
      h: 820,
      thumb: publicUrl(coverImageFallbackSrc),
    }
  }

  return {
    ...img[0],
    thumb: imageUrl(img[0].thumb),
  }
}

const getUserAvatar = (article) => {
  // 大頭貼沒照片，顯示女孩照片
  return article?.userInfo?.img ? imageUrl(article?.userInfo?.img) : avatarImageFallbackSrc
}

const withHomePageTrackingQueryString = (url) => {
  const queryString = qs.stringify({
    ...qs.parse(url.split('?')[1]),
    fromPage: {
      pageName: 'web-homepage',
      pageComponent: 'article-list',
    },
  })

  return `${url}?${queryString}`
}

const useGetArticleCardInfo = ({ article, dispatch }) => {
  const { t, isLike, userInfo, postID, like } = article
  const { isHomePage } = useContext(ExplorePageContext)

  const category = useSelector<any, any>((state) => state.posts.getIn(['posts', 'clas']))
  const title = getTitle(article)
  const coverImage = getImage(article)
  const { dateTime, formatted } = useNormalizedPublishedTime(t)
  const handleClickLike = () =>
    dispatch(postLike({ postID, isLike: isLike === 0 ? 1 : 0, data: article, via: undefined }))
  const avatar = getUserAvatar(article)

  const postUrl = `${appUrl.myhost}/${postID.replace(/\./g, '/')}`
  const postLink = isHomePage ? withHomePageTrackingQueryString(postUrl) : postUrl

  const handleArticleClick = (e) => {
    e.preventDefault()
    if (isHomePage) {
      trackWebHomePageArticleListEnterArticleEvent({
        currentPage: {
          pageName: 'web-homepage',
        },
        toPage: {
          pageUrl: postUrl,
        },
        actionTarget: postID,
      })
    } else {
      trackWebCategoryArticleListEnterArticleEvent({
        toPageUrl: postUrl,
        article: postID,
        category,
      })
    }

    window.location.href = postUrl
  }

  const handleUserClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const url = `${appUrl.myhost}/user/${userInfo.mid}`
    if (!isHomePage) {
      trackWebCategoryArticleListEnterUserProfileEvent({
        userMid: userInfo.mid,
        category,
        toPageUrl: url,
      })
    }
    window.location.href = url
  }

  return {
    dateTime,
    formatted,
    coverImage,
    userInfo,
    handleClickLike,
    avatar,
    title,
    postID,
    isLike,
    like,
    postLink,
    handleArticleClick,
    coverImageFallbackSrc,
    avatarImageFallbackSrc,
    handleUserClick,
  }
}

export default useGetArticleCardInfo
