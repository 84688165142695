import { getEventTracker } from '@libs/eventTracking'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { PageName } from '@app-core/eventTracking/PageName'

interface TrackWebCategoryArticleListLike {
  postID: string
  category: string
  actionType: 'like' | 'unlike'
}

export function trackWebCategoryArticleListLike({
  postID,
  category,
  actionType,
}: TrackWebCategoryArticleListLike) {
  getEventTracker().track({
    name: WebEventNames.WEB_CATEGORY_ARTICLE_LIST_LIKE,
    properties: {
      currentPage: getCurrentPagePropertyFor(PageName.WEB_CATEGORY),
      actionTarget: postID,
      actionType,
      category,
    },
  })
}
