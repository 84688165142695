import React from 'react'
import { imageUrl } from '@libs/appUrl'
import LayoutContent from '@components/LayoutContent'
import { useTranslation } from '@i18n'
import { IndexLink } from './components/IndexLink'

export function NotFoundError({ isApp = true, context = '404' }) {
  const { t } = useTranslation(['error'])
  return (
    <LayoutContent isApp={isApp}>
      <div className="httpStatusError">
        <img src={imageUrl('/img/404.png')} alt="Page not found" />
        <p tw="space-x-1">
          <span>{t('error', { context })}</span>
          <IndexLink>{t('goBackToIndex')}</IndexLink>
        </p>
      </div>
    </LayoutContent>
  )
}
