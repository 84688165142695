import { useAmp } from 'next/amp'

function ShowADScript() {
  const isAmp = useAmp()

  if (isAmp) return null

  return <script dangerouslySetInnerHTML={{ __html: 'window.showAD = true' }} />
}

export default ShowADScript
