import React, { useEffect, useRef, createContext } from 'react'
import Immutable from 'immutable'
import ShowADScript from '@components/ShowADScript'
import LayoutContent from '@components/LayoutContent'
import IndexHead from '../legacy-explore/components/IndexHead'
import { DesktopCarousel } from './Carousel'
import { useRNApp } from '@libs/RNApp'
import { searchUrl } from '@libs/appUrl'
import nav from '@routes/nav'
import { compose, connect, setStatic } from '@hocs'
import { bindActionCreators } from 'redux'
import {
  setType,
  setClas,
  setMode,
  getIndexPostList,
  getIndexCarousel,
  getInitialPostList,
} from '@app-core/posts/actions'
import { NotFoundError } from '@features/errors/NotFoundError'
import { trackWebCategoryViewEvent } from '@libs/eventTracking/category/web-category.view'
import { trackWebHomePageViewEvent } from '@libs/eventTracking/homePage/web-homepage.view'
import { getUrlSearchParams } from '@libs/url-utils'
import ArticleCards from './ArticleCards'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import Loading from '@components/Loading'
import ExploreTitleBar from '@features/explore/ExploreTitleBar'
import { getApolloClient } from '@libs/getApolloClient'
import { GET_POPSELECT_PRODUCT_BY_CLAS } from './graphQuery'
import {
  GetPopSelectProductByClas,
  GetPopSelectProductByClasVariables,
} from '@apollo-types/GetPopSelectProductByClas'
import { ApolloQueryResult } from '@apollo/client'
import { getEventCategories } from '@libs/categories/getCategories'

const IntersectionObserverTarget = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: 75vh;
`
const LoadingContainer = styled.div`
  padding: 50px 0;
`

const HasMoreContainer = styled.div`
  ${tw`text-gray-400 text-center text-h6`}
  padding: 50px 0;
`

export const ExplorePageContext = createContext({
  isHomePage: false,
})

const Explore = (props) => {
  const {
    isHomePage,
    carouselIsLoading,
    carouselSlider,
    notFoundError,
    posts,
    commodityModel,
    eventCategories,
  } = props
  const articles = posts.getIn(['posts', 'list'])
  const getArticlesWithAd = (articles) => {
    const articlesWithAd = []
    let curArticleNum = 0
    articles.forEach((article, index) => {
      articlesWithAd.push(article)
      // 每五篇文章塞一個廣告
      if ((index + 1) % 5 === 0) {
        articlesWithAd.push({
          isAd: true,
          adId: `div-gpt-ad-explore-article-${index + 2}`,
          // 四個 ad 輪替
          eventName: `exploreArticleAd${(curArticleNum % 4) + 1}Ready`,
        })
        curArticleNum += 1
      }
    })
    return articlesWithAd
  }
  const getArticlesWithWebCommodityModel = (articles) => {
    if (!commodityModel?.data?.getPopSelectProductByClas) {
      return
    }
    // 第一個顯示在第三列，之後每隔三列文章會插入一個模組
    const productModels = commodityModel.data.getPopSelectProductByClas
    const articlesWithCommodityModel = [...articles]
    productModels.forEach((model, index) => {
      if (index === 0) {
        articlesWithCommodityModel.splice(3 * 2, 0, { ...model, rowStart: 3 })
      } else {
        // 確認文章已經載入足夠長才執行插入
        const injectedIndex = 3 * 2 + index + index * 3 * 3
        if (articlesWithCommodityModel.length > injectedIndex) {
          articlesWithCommodityModel.splice(injectedIndex, 0, {
            ...model,
            rowStart: 3 + index * 4,
          })
        }
      }
    })
    return articlesWithCommodityModel
  }
  const getArticlesWithMobileCommodityModel = (articles) => {
    if (!commodityModel?.data?.getPopSelectProductByClas) {
      return
    }
    // 第一個模組出現在文章的第三篇的後面，之後每隔 5 篇文章穿插一個
    const productModels = commodityModel.data.getPopSelectProductByClas
    const articlesWithCommodityModel = [...articles]
    productModels.forEach((model, index) => {
      if (index === 0) {
        articlesWithCommodityModel.splice(3, 0, { ...model })
      } else {
        /* articlesWithCommodityModel.splice(3 + index + index * 5, 0, {
          ...model,
        }) */
        // 確認文章已經載入足夠長才執行插入
        const injectedIndex = 3 + index + index * 5
        if (articlesWithCommodityModel.length > injectedIndex) {
          articlesWithCommodityModel.splice(injectedIndex, 0, {
            ...model,
          })
        }
      }
    })
    return articlesWithCommodityModel
  }

  const webArticles = getArticlesWithWebCommodityModel(getArticlesWithAd(articles.toJS()))
  const mobileArticles = getArticlesWithMobileCommodityModel(articles.toJS())

  const isLoading = posts.getIn(['posts', 'isLoading'], false)
  const hasMore = posts.getIn(['posts', 'hasMore'], true)
  const category = posts.getIn(['posts', 'clas'])

  const hasSentWebHomePageViewedEvent = useRef(false)
  const hasSentCategoryPageViewedEvent = useRef(false)
  const intersectionObserverTargetRef = useRef(null)
  // 利用 ref 的方式儲存最新的 posts
  const currentPosts = useRef<any>(null)

  const { isApp } = useRNApp()
  const dispatch = useDispatch()

  const handleWebHomePageViewedEvent = () => {
    if (typeof window === 'undefined') return
    if (hasSentWebHomePageViewedEvent.current) return
    if (!isHomePage) return

    const fromPage = (getUrlSearchParams(window.location)?.fromPage ?? {}) as Record<string, any>
    trackWebHomePageViewEvent({
      currentPage: {
        pageName: 'web-homepage',
      },
      fromPage,
    })
    hasSentWebHomePageViewedEvent.current = true
  }

  const handleCategoryPageViewedEvent = () => {
    if (typeof window === 'undefined') return
    if (hasSentCategoryPageViewedEvent.current) return
    if (isHomePage) return

    const fromPage = (getUrlSearchParams(window.location)?.fromPage ?? {}) as Record<string, any>
    trackWebCategoryViewEvent({
      category,
      fromPage,
    })

    hasSentCategoryPageViewedEvent.current = true
  }

  const fetchMore = () => {
    if (!currentPosts.current) return
    const hasMore = currentPosts.current.getIn(['posts', 'hasMore'], true)
    const isLoading = currentPosts.current.getIn(['posts', 'isLoading'], false)
    if (hasMore && !isLoading && getIndexPostList) {
      const type = currentPosts.current.getIn(['posts', 'type'])
      const clas = currentPosts.current.getIn(['posts', 'clas'])
      const mode = currentPosts.current.getIn(['posts', 'mode'])
      const page = currentPosts.current.getIn(['posts', 'page'])
      const score = currentPosts.current.getIn(['posts', 'score'])
      const touch = currentPosts.current.getIn(['posts', 'currentList']).toJS()
      dispatch(getIndexPostList({ type, clas, mode, page, score, touch }))
    }
  }

  const handleCreateIntersectionObserver = () => {
    if (intersectionObserverTargetRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (!entry.isIntersecting) {
            return
          }
          fetchMore()
        },
        {
          threshold: [0, 0.25, 0.5, 0.75, 1],
        },
      )
      observer.observe(intersectionObserverTargetRef.current)
      return observer
    }
  }

  useEffect(() => {
    const intersectionObserver = handleCreateIntersectionObserver()
    handleWebHomePageViewedEvent()
    handleCategoryPageViewedEvent()
    return () => intersectionObserver.disconnect()
  }, [])

  useEffect(() => {
    // 隨時更新最新的 posts 到 currentPosts，讓 fetchMore 可以使用
    currentPosts.current = posts
  })

  if (notFoundError) {
    return <NotFoundError isApp={isApp} />
  }

  const [activeEvent] = eventCategories.filter(({ id }) => id === category)

  return (
    <LayoutContent isApp={isApp} hasNavigation eventCategories={eventCategories}>
      <ExplorePageContext.Provider value={{ isHomePage }}>
        <ShowADScript />
        <IndexHead activeEvent={activeEvent} />
        {isHomePage && carouselSlider.size && (
          <DesktopCarousel isLoading={carouselIsLoading} carouselSlider={carouselSlider.toJS()} />
        )}
        <div tw="relative">
          <ExploreTitleBar category={category} activeEvent={activeEvent} />
          <ArticleCards webArticles={webArticles} mobileArticles={mobileArticles} />
          <IntersectionObserverTarget ref={intersectionObserverTargetRef} />
          {isLoading && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
          {!hasMore && <HasMoreContainer>沒有更多文章</HasMoreContainer>}
        </div>
      </ExplorePageContext.Provider>
    </LayoutContent>
  )
}

type ExploreType = 'explore' | 'officialExplore' | 'homepage'

const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIndexPostList,
      getIndexCarousel,
      setClas,
      setMode,
      setType,
      getInitialPostList,
    },
    dispatch,
  )
const mergeProps = (state, dispatchProps, ownProps) => {
  const { posts } = state
  const { getIndexPostList, getIndexCarousel, setClas, setMode, setType, getInitialPostList } =
    dispatchProps

  const carouselPost = posts.getIn(['posts', 'carousel', 'carouselPost'], Immutable.fromJS([]))
  const carouselSlider = posts.getIn(['posts', 'carousel', 'carouselSlider'], Immutable.fromJS([]))
  const carouselIsLoading = posts.getIn(['posts', 'carousel', 'isLoading'], false)

  return {
    ...state,
    ...ownProps,
    getIndexCarousel,
    getIndexPostList,
    setClas,
    setMode,
    setType,
    getInitialPostList,
    carouselPost,
    carouselSlider,
    carouselIsLoading,
  }
}

export default compose(
  setStatic('getInitialProps', async ({ store, ...ctx }) => {
    const { query, asPath = '', namespacesRequired, res } = ctx
    const client = getApolloClient(ctx as any)
    const { s, p, preview, clas, website } = query
    const isHomePage = asPath === '/'
    const allCategories = [...nav.categories]
    const events = []
    const eventCategories = await getEventCategories(client)
    for (const eventCatgory of eventCategories) {
      // 匿名聊移除
      if (eventCatgory.name !== '匿名聊') {
        allCategories.push(eventCatgory.id)
        events.push(eventCatgory)
      }
    }
    /**
     * 有三種頁面使用此 Explore
     * 首頁 (homepage), /
     * 探索頁 (explore), /explore/[clas]
     * 官方探索頁 (officialExplore), /[website]
     */

    // <-- 判斷頁面類型 -->
    let exploreType: ExploreType = 'homepage'
    if (clas) exploreType = 'explore'
    if (website) exploreType = 'officialExplore'

    // <-- 針對 query string 進行導向 -->
    /**
     * [redirect] s: search/post/
     * TODO: 確定此邏輯是否有使用，沒用到可以移除
     */
    if (s) res.redirect(searchUrl(s))

    /**
     * [redirect] preview: wordpress preview `/demo2/${req.query.p}`
     * TODO: 確定此邏輯是否有使用，沒用到可以移除
     */
    if (p && preview) res.redirect(`/demo2/${p}&t=${new Date().getTime()}`)

    // <-- 檢查 params 是否符合規範，沒有導向 404 -->
    // 不在 explore 規範內
    if (exploreType === 'explore' && !allCategories.includes(clas)) {
      res.status(404)
      return { notFoundError: true, namespacesRequired }
    }
    // 不在 officialExplore 規範內
    if (exploreType === 'officialExplore' && !allCategories.includes(website)) {
      res.status(404)
      return { notFoundError: true, namespacesRequired }
    }

    // <-- 根據 route 設定 store state 與 payload -->
    // queryType 是 explore 當頁面是 首頁, 探索頁, 或是官方活動
    // queryType 是 website 當頁面是 官方探索頁
    let queryType = 'explore'
    if (exploreType === 'officialExplore' && website !== 'trend') {
      await store.dispatch(setType({ type: 'website' }))
      queryType = 'website'
    } else {
      await store.dispatch(setType({ type: 'explore' }))
    }

    // 依照頁面拿取 queryClas, 首頁是 featured
    let queryClas = 'featured'
    if (exploreType === 'explore') queryClas = clas
    if (exploreType === 'officialExplore') queryClas = website
    await store.dispatch(setClas({ clas: queryClas }))

    // 非首頁與探全部使用預設模式 guess，其他設為 new
    if (!isHomePage) {
      await store.dispatch(setMode({ mode: 'new' }))
    }
    const mode = (await store.getState().posts.getIn(['posts', 'mode'])) || 'new'

    // <-- 拿取資料 -->
    // 首頁或探索全部多拿 carousel
    // TODO: 遷移到 hooks
    if (isHomePage) {
      await store.dispatch(getIndexCarousel())
    }

    // 拿初始文章
    await store.dispatch(
      getInitialPostList({
        type: queryType,
        clas: queryClas,
        mode,
        paginationIndex: 1,
      }),
    )

    // 拿商品模組
    let popSelectProduct: ApolloQueryResult<GetPopSelectProductByClas> = {
      data: null,
      loading: null,
      error: null,
      networkStatus: null,
    }
    try {
      popSelectProduct = await client.query<
        GetPopSelectProductByClas,
        GetPopSelectProductByClasVariables
      >({
        query: GET_POPSELECT_PRODUCT_BY_CLAS,
        variables: {
          clas: isHomePage ? 'weball' : clas,
        },
      })
    } catch (e) {
      popSelectProduct.error = e
    }

    return {
      isHomePage,
      notFoundError: false,
      namespacesRequired,
      commodityModel: popSelectProduct,
      eventCategories: events,
    }
  }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(Explore)
