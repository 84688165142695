import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  lightFormat,
  formatISO,
} from 'date-fns'
import { useTranslation } from '@i18n'

export const useNormalizedPublishedTime = (input: Date | number, base = Date.now()) => {
  const { t } = useTranslation('poptime')
  try {
    const time = input instanceof Date ? input : new Date(input)

    const diff = {
      second: differenceInSeconds(base, time),
      minute: differenceInMinutes(base, time),
      hour: differenceInHours(base, time),
      day: differenceInDays(base, time),
      week: differenceInWeeks(base, time),
      month: differenceInMonths(base, time),
      year: differenceInYears(base, time),
    }

    const result = {
      formatted: lightFormat(time, diff.year === 0 ? 'MM-dd' : 'yyyy-MM-dd'),
      dateTime: formatISO(time),
    }

    if (diff.second > 0 && diff.second < 60) {
      result.formatted = t('just') // over 0s ~ 1min (60s)
    } else if (diff.second >= 60 && diff.minute < 59) {
      result.formatted = t('minsAgo', { count: diff.minute }) // over 1min ~ 59mins
    } else if (diff.hour >= 1 && diff.hour < 24) {
      result.formatted = t('nHoursAgo', { count: diff.hour }) // over 1hour ~ 24hours
    } else if (diff.hour >= 24 && diff.day <= 4) {
      result.formatted = t('nDaysAgo', { count: diff.day }) // over 1day ~ 4days
    } else if (diff.day > 4) {
      result.formatted = lightFormat(time, 'yyyy.MM.dd') // over 4days
    }

    return result
  } catch (error) {
    return { formatted: '---', dateTime: undefined }
  }
}
